import React from "react";

import styled from "styled-components";
import { typography, space, color } from "styled-system";

const Text = styled.div`
    margin: 0;
    ${typography}
    ${color}
    ${space}

    a {
        color: white;
    }
`;

export const Title = React.forwardRef((props, forwardedRef) => (
  <Text
    color="white"
    fontFamily="cairo"
    fontWeight={600}
    as="h1"
    lineHeight={1.125}
    fontSize={4}
    mb={3}
    {...props}
    ref={forwardedRef}
  />
));

export const Subtitle = React.forwardRef((props, forwardedRef) => (
  <Text
    color="offWhite"
    fontFamily="cairo"
    fontWeight={600}
    as="h3"
    size={3}
    lineHeight={1.5}
    {...props}
    ref={forwardedRef}
  />
));

export const BodyText = React.forwardRef((props, forwardedRef) => (
  <Text
    fontFamily="cairo"
    color="white"
    fontWeight={300}
    as="p"
    fontSize={2}
    lineHeight={1.75}
    {...props}
    ref={forwardedRef}
  />
));

export const SmallTitle = React.forwardRef((props, forwardedRef) => (
  <Text
    fontFamily="futura"
    color="high"
    textTransform={"uppercase"}
    letterSpacing={"1px"}
    fontWeight={200}
    fontSize={"10px"}
    {...props}
    ref={forwardedRef}
    my={2}
    css={`
      text-transform: uppercase;
      font-weight: normal;

      &:after {
        content: "_";
      }
    `}
  />
));

export const SmallText = React.forwardRef((props, forwardedRef) => (
  <Text
    color="white"
    fontWeight={400}
    as="small"
    fontFamily="cairo"
    fontSize={0}
    {...props}
    ref={forwardedRef}
  />
));

export default Text;
