import React, { useLayoutEffect } from "react";

import SplitText from "../gsap/SplitText";
import { TimelineMax, Back, Expo } from "gsap";

const animation = {
  opacity: 0,
  y: -10,
  rotationX: -8,
  rotationY: 24,
  ease: Back.easeOut.config(0.8),
  transformOrigin: "0 0 -50",
};

export default function useXAnimation($container) {
  const elementsTls = React.useRef([]);
  const outerTl = React.useRef();

  useLayoutEffect(() => {
    const els = $container.current.querySelectorAll("[data-reveal]");

    outerTl.current = new TimelineMax();

    outerTl.current.pause();

    els.forEach((el, i) => {
      const { reveal } = el.dataset;

      if (reveal === "letters") {
        // return () => {
        //   tl.stop();
        //   splitText.revert();
        // };

        elementsTls.current.push(() => {
          const tl = new TimelineMax();
          const splitText = new SplitText(el, { type: "chars" });
          tl.staggerFrom(splitText.chars, 0.75, animation, 0.08);

          return tl;
        });
      } else if (reveal === "lines") {
        elementsTls.current.push(() => {
          const tl = new TimelineMax();
          const splitText = new SplitText(el, { type: "lines" });
          tl.staggerFrom(splitText.lines, 0.75, animation, 0.06);

          return tl;
        });
      } else if (reveal === "image") {
        elementsTls.current.push(() => {
          const tl = new TimelineMax();
          tl.fromTo(
            el,
            1.5,
            {
              opacity: 0,
              y: 100,
            },
            {
              opacity: 1,
              eaese: Expo.easeIn,
              y: 0,
            },
            "+=.5"
          );

          return tl;
        });
      } else {
        elementsTls.current.push(() => {
          const tl = new TimelineMax();

          tl.from(el, 0.75, animation, 0.1);

          return tl;
        });
      }

      //   const tl = new TimelineMax({ repeatDelay: 1, delay: i * 0.1 });

      //   tl.from(el, 0.75, animation, 0.1);

      //   return () => {
      //     tl.stop();
      //   };
    });

    elementsTls.current.forEach(tlFn => {
      outerTl.current.add(tlFn(), "-=0.6");
    });
  }, []);

  const start = React.useCallback(() => {
    outerTl.current.play();
  }, [outerTl.current]);

  return [start];
}
